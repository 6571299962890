import React from 'react';
import Cookies from 'universal-cookie/es6';
import { LogoutApp } from '../data/api';


interface OwnProps { setUser: any }
interface LogoutProps extends OwnProps { }

const Logout: React.FC<LogoutProps> =async ({ setUser }) => {

  LogoutApp();
  setUser('');
  const cookies = new Cookies();
  cookies.remove('useremail');

  let jobId = sessionStorage.getItem('savedJobId');
  if (jobId != null) {
    sessionStorage.removeItem('savedJobId');
  }

  window.location.href = "/tabs/Joblist";
  

  return (
    <></>
  )
}



export default Logout;
