import React, { useState } from 'react';
import { IonHeader, IonContent, IonToolbar, IonTitle, IonItem,  IonButton, IonIcon, IonButtons, useIonAlert, IonCard, IonText, IonInput, IonCardContent } from '@ionic/react';
import { closeOutline, eyeOutline, flashOutline, mailUnreadOutline } from 'ionicons/icons';
import "react-widgets/styles.css";
import './Generic.css';
import { validateEmail, GetDailyCode } from '../data/utilities';
import { VerifyAppEmail, EmployeeAppCheck } from '../data/api';
import Cookies from 'universal-cookie/es6';
import { useQuery } from 'react-query';



interface ModalApplyProps {
  jobid : any,
  setShowModalApply: any
}
const ModalApply: React.FC<ModalApplyProps> = ({ jobid, setShowModalApply }) => {

  sessionStorage.removeItem("savedJobId");
  const cookies = new Cookies();

  let id = jobid;


  const [present] = useIonAlert();
  const [showTempEmail, setShowTempEmail] = useState(cookies.get('useremail'));
  const [showOption, setShowOption] = useState(false);

  
  return (<>
    <IonHeader >
      <IonToolbar color="primary">
        <IonTitle color="white">Email Verification</IonTitle>
        <IonButtons slot="end" onClick={() => setShowModalApply(false)} color="transparent">
          <IonButton>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding" color="light">
      <IonCard>
        <IonCardContent>
          <IonItem>
            <IonText>
              <IonIcon icon={mailUnreadOutline} color="danger" size="large" /> &nbsp;&nbsp; Please enter your email below; make sure it is accurate and valid.  This email address
              will be used to contact, notify, and/or update you about your application. <p></p>If you are a current Orem City employee, please use your orem.gov email and password to log in.
              </IonText>
            </IonItem>
          <IonItem lines="none">   
            <IonInput
              type="text"
              name="email"
              style={{ textTransform: 'lowercase' }}

              placeholder="Your email address here"
              value={showTempEmail}
              onIonChange={(e) => {         
                 setShowTempEmail(e.detail.value!);
                }
              }
            />
            <IonButton style={{ height: '40px', width: '150px' }}
              color="primary"
              onClick={async () => {
                if (validateEmail(showTempEmail))
                {
                  cookies.set("useremail", showTempEmail);
                  // Check if the user exists in our database
                  const result = await VerifyAppEmail(showTempEmail, false);

                  if (result == "True") {
                    if (id > 0) {


                      // Check Employee or not
                      if (showTempEmail.includes("orem.org") || showTempEmail.includes("orem.gov")) {

                         const response = {
                          dailycode: GetDailyCode(showTempEmail),
                          email: showTempEmail
                         }

                        const returnValue = EmployeeAppCheck(response)
                          .then((res) => {
                            if (res === "redirectUpload") {
                              window.location.href = "/tabs/upload/" + showTempEmail + "/" + id.toString();
                            }
                            else {
                              //Application for a job
                              setShowOption(true);
                            }
                          }).catch((err) => setShowOption(true));
                      }
                      else {
                        //Application for a job
                        setShowOption(true);
                      }
                    }
                    else {
                      // Check in as a new user
                      let message = "You have already had an account with us. Please click<a href='/tabs/login/" + showTempEmail + "'> here </a> to login. If you do not remember your password, you can click<a href='/tabs/resetpwd/"+ showTempEmail +"'> here</a> to reset it!";
                      present(message);
                    }
                    
                  }
                  else {

                    if (id != undefined) { window.location.href = "/tabs/register/" + showTempEmail+"/"+id.toString();}
                       else { window.location.href = "/tabs/register/" + showTempEmail;}
                    
                    
                  }
                }
                } 
             }
            >Enter</IonButton>
          </IonItem>
        </IonCardContent>
      </IonCard>

      {showOption &&
        <>
        <IonCard>
          <IonCardContent>
            {/*<IonItem href="javascript:void(0);" onClick={() => {*/}
            {/*  window.location.href = '/tabs/eequestionnaire/' + showTempEmail + "/" + id.toString();*/}
            {/*}} >*/}
            {/*  <IonText>*/}
            {/*    <IonIcon icon={flashOutline} size="large" color="primary" />  Submit this job application <strong>without</strong> reviewing or updating my personal information and resume.*/}
            {/*  </IonText>*/}
            {/*</IonItem>*/}

            <IonItem href="javascript:void(0);" onClick={() => {
              sessionStorage.setItem("savedJobId", id.toString());
              window.location.href = '/tabs/login/' + showTempEmail
            }} >
              <IonText>
                <IonIcon icon={eyeOutline} size="large" color="primary"  />   Log in to review/update my personal information and resume before applying for this job.
              </IonText>
            </IonItem>
          </IonCardContent>
        </IonCard>
        </>
     }
      
    </IonContent>
    

  </>);
}




export default ModalApply;

