import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,  IonContent,  IonHeader, IonIcon, IonInput, IonItem, IonItemDivider, IonLabel, IonMenuButton,  IonModal,  IonPage,  IonTitle, IonToolbar, useIonAlert, useIonLoading } from '@ionic/react';
import { Formik } from 'formik';
import {  personAddOutline, returnDownBackOutline} from 'ionicons/icons';
import React, {  useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { UpdateAppJobLog } from '../data/api';
import { GetDailyCode } from '../data/utilities';
import { EEQSchema } from '../data/validations';
//import { useGlobalContext } from '../components/MyGlobalContext';
import { UpdatePrescreenResponse, AppPrescreenExits, GetAppJobDetail } from '../data/api';





interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ email: any, jobindex: any }> { }
interface GenericProps extends RouteProps, StateProps { }

const EEQuestionnaire: React.FC<GenericProps> = ({ match, title }) => {
  const email = match.params.email;
  const jobindex = match.params.jobindex;
  const [present] = useIonAlert();
  const [presentLoading, dismiss] = useIonLoading();


  const [showSubmit, setShowSubmit] = useState(true);
  const [showRelated, setShowRelated] = useState(false);
  const [showJobDept, setShowJobDept] = useState('');
  const [showJobTitle, setShowJobTitle] = useState('')


  const prescreenQuestionsQuery = useQuery("PrescreeningQuery", () => AppPrescreenExits(match.params.jobindex, match.params.email),
    {
      onSuccess: (data: any) => {
        if( data !== null)
          {
            data.map((dataString: any) => {
              if (dataString.questionresponse === "") { setShowSubmit(false); }
            })
          }
      }
    }
  );


  const requestQuery = useQuery("JobDetailQuery", () => GetAppJobDetail(jobindex),
    {
      onSuccess: (data: any) => {
        setShowJobDept(data.hjporggrp.trim());
        setShowJobTitle(data.hjptitle.trim());
      }
    }
  );

  //function SetFilterValueA(value: string, flag: boolean) {
  //  var myArray = new Array<string>();
  //  myArray = showDept;
  //  arrayremove(myArray, value);
  //  if (flag) {
  //    myArray.push(value);
  //  }
  //  setShowDept(myArray);
  //}

  //function SetFilterValueB(value: string, flag: boolean) {
  //  var myArray = new Array();
  //  myArray = showType;
  //  arrayremove(myArray, value);
  //  if (flag) {
  //    myArray.push(value);
  //  }
  //  setShowType(myArray);
  //}


  //useEffect(() => {
    
  //  if (prescreenQuestionsQuery.isSuccess && prescreenQuestionsQuery.data === 1) {
  //    setShowModalPrescreenQuestion(true);
  //  }
    
  //}, [])

  return (
    <IonPage>
      
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="light"><h2>Questionnaire for [{requestQuery.isSuccess && (<>{requestQuery.data.hjptitle}</>) }]</h2></IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/jobdetail/' + jobindex.toString() }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

       

        <div style={{ padding: "5px" }}>

          {prescreenQuestionsQuery.isSuccess && prescreenQuestionsQuery.data !== null && prescreenQuestionsQuery.data.length > 0 &&
            (<>

              <IonCard>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                  <IonCardHeader>
                  <IonCardTitle>Prescreening Questionnaire</IonCardTitle>
                  <IonCardSubtitle>
                    Due to the nature of job you are applying,  we need to collect some additional information from you.  Please
                    answer the following questions
                  </IonCardSubtitle>
                  <IonItemDivider></IonItemDivider>
                  </IonCardHeader>

                  <IonCardContent>
                  {prescreenQuestionsQuery.data.map((dataString: any) => (
                    <IonItem key={dataString.questionid}>
                      <IonLabel>{dataString.questiondescription}</IonLabel>
                      <div className="select">
                        <select id={dataString.questionid}
                          onChange={(e) => {

                            const response = {
                              dailycode: GetDailyCode(email),
                              email: email,
                              questionid: dataString.questionid.toString(),
                              questionresponse: e.target.value
                            }
                            const returnValue = UpdatePrescreenResponse(response)
                              .then((res) => {
                                if (res !== "success") {
                                    present("Sorry that we are unable to update your selection due to the following error:" + res);
                                }
                                setShowSubmit(true);
                                prescreenQuestionsQuery.refetch();
                              })
                          }
                          }
                          value={dataString.questionresponse}
                        >
                         <option value=''>Select One</option>
                         <option value="Y">Yes</option>
                         <option value="N">No</option>
                         </select>
                      </div>
                     </IonItem> 
                  ))}




                  </IonCardContent>
                </div>
              </IonCard>



            </>)}






          <Formik
            initialValues={{
              HASOURCE: null,
              HAOVER40: null,
              HAGENDER: null,
              HAETHNICTY: null,
              HARELNAME : null
            }}

            validationSchema={EEQSchema}

            onSubmit={values => {

              const response = {
                dailycode: GetDailyCode(email),
                haemail: email,
                hjpindex: jobindex,
                haover40: values.HAOVER40,
                hagender: values.HAGENDER,
                haethnicty: values.HAETHNICTY,
                haccomodtn: null,
                hasource: values.HASOURCE,
                Hawhorefer : values.HARELNAME
              }

              setShowSubmit(false);

              const returnValue = UpdateAppJobLog(response)
                .then((res) => {
                  if (res === "success") {

                    present({
                      header: "Success!",
                      message: "Thank you for applying for the [" + showJobTitle  +"] job. Your application has been successfully submitted.",
                      buttons: [
                         {
                          text: "Return to Job List", handler: () => {
                            window.location.href = '/tabs/joblist/';
                          }
                         }                     
                      ]
                    })
                   
                  }
                  else {
                    present("Sorry that we are unable to accept your application due to the following error:" + res);
                  }
                })

            }}
          >

            {(formikProps: any) => (
              <form
                onSubmit={formikProps.handleSubmit}
                style={{ paddingBottom: "40px" }}
              >
            

                <IonCard>
                      <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                        <IonCardHeader>
                          <IonCardTitle>Federal Questionnaire (EEQ)</IonCardTitle>
                          <IonCardSubtitle>
                            The City of Orem is an equal opportunity employer. Government agencies, at times, require periodic reports on racial / ethnic origins and other protected status of job applicants and employees.
                            This data is for statistical analysis with respect to equal employment opportunity. We appreciate your voluntary cooperation in participating in this questionnaire.
                      </IonCardSubtitle>
                      <IonItemDivider></IonItemDivider>
                        </IonCardHeader>
                        <IonCardContent>
                          <IonItem>
                            <IonLabel >Your gender?</IonLabel>
                        <div className="select">
                          <select id="HAGENDER"
                            onChange={(e) => {                             
                              formikProps.values.HAGENDER = e.target.value;                             
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HAGENDER}  >
                            <option value='null'>Select One</option>
                            <option value="F">Female</option>
                            <option value="M">Male</option>
                            <option value="O">Other</option>
                            <option value="V">Choose not to disclose</option>
                          </select>
                          <span className="focus"></span>
                        </div>



                          </IonItem>

                          <IonItem>
                            <IonLabel>Are you age 40 or older?</IonLabel>
                        <div className="select">
                          <select id="HAOVER40"
                            onChange={(e) => {
                              formikProps.values.HAOVER40 = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HAOVER40}  >
                            <option value='null'>Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                            <option value="V">Choose not to disclose</option>
                          </select>
                          <span className="focus"></span>
                        </div>

                          </IonItem>

                          <IonItem>
                            <IonLabel>Ethnic Origin?</IonLabel>
                        <div className="select">
                          <select id="HAETHNICTY"
                            onChange={(e) => {
                              formikProps.values.HAETHNICTY = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HAETHNICTY}  >
                            <option value='null'>Select One</option>
                            <option value="A">Asian</option>
                            <option value="B">Black or African American</option>
                            <option value="H">Hispanic or Latino</option>
                            <option value="I">Native American or Alaska Native</option>
                            <option value="O">Pacific Islander or Native Hawaiian</option>
                            <option value="W">White</option>
                            <option value="T">Two or more races</option>
                            <option value="N">Other</option>
                            <option value="V">Choose not to disclose</option>
                          </select>
                          <span className="focus"></span>
                        </div>
                          </IonItem>
                        </IonCardContent>
                      </div>
                    </IonCard>



                <IonCard>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                    {/*<IonCardHeader>*/}
                    {/*  <IonCardTitle>How would you like to enter education and employment history?</IonCardTitle>*/}
                    {/*</IonCardHeader>*/}
                    <IonCardContent>
                      <IonItem>
                        <IonLabel class="ion-text-nowrap" >How did you hear of this job opening? </IonLabel>
                        <div className="select">
                          <select id="HASOURCE"
                            onChange={(e) => {                            
                                formikProps.values.HASOURCE = e.target.value;
                              if (e.target.value === "E") { setShowRelated(true); } else { setShowRelated(false) }
                                 formikProps.handleChange(e);
                               }
                            }
                            value={formikProps.values.HASOURCE}  >
                            <option value='null' style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="W">City of Orem website or job board</option>
                            <option value="E">From a current Orem city employee</option>
                            <option value="R">Job notification e-mail from HR</option>
                            <option value="S">City of Orem Social Media</option>
                            <option value="F">Utah Department of Workforce Services</option>
                            <option value="M">Handshake</option>
                            <option value="T">Utah League of Cities and Towns</option>
                            <option value="O">Other</option>
                          </select>
                          <span className="focus"></span>
                         </div>


                      </IonItem>

                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HASOURCE && formikProps.errors.HASOURCE}
                      </IonLabel>
                      {showRelated && (
                        <IonItem>
                          <IonIcon icon={personAddOutline} size="large" color="danger" slot="start" />&nbsp;&nbsp;
                          <IonLabel slot="start">Name of the employee:</IonLabel>
                          <IonInput 
                            type="text"
                            name="HARELNAME"
                            onIonChange={formikProps.handleChange}
                            min="1"
                            max="50"
                            placeholder=""
                            autocomplete="on"
                            value={formikProps.values.HARELNAME}
                          />
                        </IonItem>
                        )}
                      


                    </IonCardContent>
                  </div>
                </IonCard>

                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {showSubmit && (
                    <IonButton
                      color="danger"
                      expand="block"
                      type="submit"
                      size="large"
                    > Final Submit</IonButton>
                    )}
                </div>

              </form>
            )}

          </Formik>
        </div>



     </IonContent>
    </IonPage>
  )
}



export default EEQuestionnaire;
