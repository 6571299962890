import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem,  IonLabel, IonMenuButton, IonModal, IonPage, IonRow, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { Formik } from 'formik';
import {  alertCircleOutline, returnDownBackOutline } from 'ionicons/icons';
import React, { useEffect,  useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppStateList, UpdateAppUserProfile } from '../data/api';
import { RegistrationSchema } from '../data/validations';
import ModalApply from '../components/ModalApply';
import ModalCDLConfirm from '../components/ModalCDLConfirm';
import { GetDailyCode } from '../data/utilities';
import './Generic.css';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ email: any, jobindex :any }> { }
interface GenericProps extends RouteProps, StateProps { }

const Application: React.FC<GenericProps> = ({ match, title }) => {


  const [present] = useIonAlert();
  let email = match.params.email;

  let jobindex= 0;
  if (match.params.jobindex !== undefined) {
    jobindex = match.params.jobindex
  }


  const [showModalApply, setShowModalApply] = useState(false);
  const [showModalCDMConfirm, setShowModalCDMConfirm] = useState(false);
  

  const [showRelated, setShowRelated] = useState(false);
  const [showVeteran, setShowVeteran] = useState(false);
  const [showDisabled, setShowDisabled] = useState <boolean>(false);

  useEffect(() => {
    if(email === undefined) {
     setShowModalApply(true);
    }
    if (jobindex === 0) { setShowDisabled(false);}
    
  }, []);

  
  const showModalAndWait = () => {
    return new Promise((resolve) => {
      const handleConfirm = (confirmed) => {
        setShowModalCDMConfirm(false);
        resolve(confirmed);
      };
      setShowModalCDMConfirm(true);
    });
  };


  const handleCDLChange = (setFieldValue, value) => {
    setFieldValue("HACDL", value);
  };


  const stateQuery = useQuery("GetStateList", () => GetAppStateList());
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="white">Application Registration</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/jobdetail/' + jobindex }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">

        <div style={{ padding: "5px" }}>
       
              <Formik
            initialValues={{
              HALAST: null,
              HAFIRST: null,
              HAMIDDLE: null,
              HAFRST2: null,
              HAADDRESS1: null,
              HAADDRESS2: null,
              HACITY: null,
              HASTATE: 'UT',
              HAZIPCODE: null,
              HACNTCTPHN: null,
              HACANTEXT: null,
              HAVALIDDL: null,
              HACDL: null,
              HAOREMWORK: null,
              HALEGAL: null,
              HAMILITARY: null,
              HANEEDASST: null,
              HARELATED: null,
              HARELNAME: null,
              HARELREL: null,
              HAHDEGREE: null,
              HARESUME: null,
              HASOURCE: null,
              HAOVER40: null,
              HAGENDER: null,
              HAETHNICTY: null,
              PASSWORD: null

            }}

            validationSchema={RegistrationSchema}

            onSubmit={async values => {

              
              const response = {
                    dailycode: GetDailyCode(email),
                    haemail: email,
                    halast: values.HALAST,
                    hafirst: values.HAFIRST,
                    hamiddle: values.HAMIDDLE,
                    hafrst2: values.HAFRST2,
                    haaddress1: values.HAADDRESS1,
                    haaddress2: values.HAADDRESS2,
                    hacity: values.HACITY,
                    hastate: values.HASTATE,
                    hazipcode: values.HAZIPCODE,
                    hacntctphn: values.HACNTCTPHN,
                    havaliddl: values.HAVALIDDL,
                    hacdl: values.HACDL,
                    haoremwork: values.HAOREMWORK,
                    halegal: values.HALEGAL,
                    hamilitary: values.HAMILITARY,
                    harelated: values.HARELATED,
                    harelrel: values.HARELREL,
                    harelname: values.HARELNAME,
                    haresume: values.HARESUME,
                    hahdegree: values.HAHDEGREE,
                    hacantext: values.HACANTEXT,
                    haspasswrd: values.PASSWORD
                  }
                  
                  
              const returnValue = await UpdateAppUserProfile(response);

              if (returnValue === "success") {

                if (values.HARESUME === "Y") {
                  //--- Go to Upload Page  /// Pass Job Id
                  window.location.href = "/tabs/upload/" + email + "/" + jobindex.toString();
                }
                else {
                  //--- Go to Manually Enter data page  /// Pass Job Id
                  window.location.href = "/tabs/manual/" + email + "/" + jobindex.toString();
                }       
              }
              else {
                present("Sorry that we are unable to create your profile due to the following error:" + returnValue);
              }

             }}
              >





            {(formikProps: any) => (

              <form
                    onSubmit={formikProps.handleSubmit}
                    style={{ paddingBottom: "40px" }}
              >
                <IonCard color="lightblue">
                  <IonCardContent>
                    <IonItem lines="none" color="transparent">
                      <IonLabel><strong>Your Email</strong>: {email}</IonLabel>
                    </IonItem>
                  </IonCardContent>
                </IonCard>

                <IonCard>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                  <IonCardHeader>
                    <IonCardTitle>Your Full Name:</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                      <IonItem>
                        <IonLabel position="floating" >*Last name</IonLabel>
                        <IonInput
                          type="text"
                          name="HALAST"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="50"
                          placeholder=""
                          autocomplete="family-name"
                          value={formikProps.values.HALAST}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HALAST && formikProps.errors.HALAST}
                      </IonLabel>
                      <IonItem>
                        <IonLabel position="floating" >*First name</IonLabel>
                        <IonInput
                          type="text"
                          name="HAFIRST"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="50"
                          placeholder=""
                          autocomplete="given-name"
                          value={formikProps.values.HAFIRST}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HAFIRST && formikProps.errors.HAFIRST}
                      </IonLabel>
                      <IonItem>
                        <IonLabel position="floating">Middle name</IonLabel>
                        <IonInput
                          type="text"
                          name="HAMIDDLE"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="50"
                          placeholder=""
                          autocomplete="on"
                          value={formikProps.values.HAMIDDLE}
                          required={false}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Prefered name</IonLabel>
                        <IonInput
                          type="text"
                          name="HAFRST2"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="50"
                          placeholder=""
                          autocomplete="given-name"
                          value={formikProps.values.HAFRST2}
                          required={false}
                        />
                      </IonItem>
                   
                    </IonCardContent>
                  </div>
                </IonCard>

                <IonCard>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                  <IonCardHeader>
                    <IonCardTitle>Your Address:</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                      <IonItem>
                        <IonLabel position="floating" >*Address 1</IonLabel>
                        <IonInput
                          type="text"
                          name="HAADDRESS1"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="100"
                          placeholder=""
                          autocomplete="street-address"
                          value={formikProps.values.HAADDRESS1}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HAADDRESS1 && formikProps.errors.HAADDRESS1}
                      </IonLabel>
                      <IonItem>
                        <IonLabel position="floating" >Address 2</IonLabel>
                        <IonInput
                          type="text"
                          name="HAADDRESS2"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="100"
                          placeholder=""
                          autocomplete="on"
                          value={formikProps.values.HAADDRESS2}
                          required={false}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating" >*City</IonLabel>
                        <IonInput
                          type="text"
                          name="HACITY"
                          onIonChange={formikProps.handleChange}
                          min="1"
                          max="100"
                          autocomplete="on"
                          placeholder=""
                          value={formikProps.values.HACITY}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HACITY && formikProps.errors.HACITY}
                      </IonLabel>
                      <IonItem>                     
                        <IonLabel>*State</IonLabel>
                       
                        <div className="select">
                          <select id="HASTATE"
                            onChange={(e) => {
                              formikProps.values.HASTATE = e.target.value;
                              formikProps.handleChange(e);
                            }}
                            value={formikProps.values.HASTATE}>
                            <option value='AL'>Alabama</option>
                            <option value='AK'>Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>

                          </select>
                          <span className="focus"></span>
                        </div>
                      </IonItem>
                      <IonItem>
                          <IonLabel position="floating" >*Zip</IonLabel>
                          <IonInput
                            type="number"
                            name="HAZIPCODE"
                            onIonChange={formikProps.handleChange}
                            min="10000"
                            max="99999"
                           placeholder=""
                          autocomplete="postal-code"
                            value={formikProps.values.HAZIPCODE}
                            required={true}
                          />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HAZIPCODE && formikProps.errors.HAZIPCODE}
                      </IonLabel>
                      <IonItem>
                        <IonLabel position="floating" >*Primary phone #</IonLabel>
                        <IonInput
                          name="HACNTCTPHN"
                          type="number"
                          autocomplete="on"
                          value={formikProps.values.HACNTCTPHN}
                          onIonChange={formikProps.handleChange}
                          required={true}
                        />
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HACNTCTPHN && formikProps.errors.HACNTCTPHN}
                      </IonLabel>
                      <IonItem>
                        <IonLabel class="ion-text-nowrap" >If this is a cell phone, can we text you?</IonLabel>

                        <div className="select">
                          <select id="HACANTEXT"
                            onChange={(e) => {
                              formikProps.values.HACANTEXT = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HACANTEXT}  >

                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                          <span className="focus"></span>
                        </div>

                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HACANTEXT && formikProps.errors.HACANTEXT}
                      </IonLabel>
                    </IonCardContent>
                  </div>
                </IonCard>

                <IonCard>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                    <IonCardHeader>
                      <IonCardTitle>Addtional Information:</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      

                      <IonItem>
                        <IonLabel >Are you eligible for lawful employment in US?</IonLabel>
                        <div className="select">
                          <select id="HALEGAL"
                            onChange={(e) => {
                              formikProps.values.HALEGAL = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HALEGAL}  >
                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                          <span className="focus"></span>
                        </div>


                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HALEGAL && formikProps.errors.HALEGAL}
                      </IonLabel>

                      <IonItem>
                        <IonLabel class="ion-text-nowrap" >Have you ever worked for the City of Orem? </IonLabel>
                        <div className="select">
                          <select id="HAOREMWORK"
                            onChange={(e) => {
                              formikProps.values.HAOREMWORK = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HAOREMWORK}  >
                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                          <span className="focus"></span>
                        </div>

                      </IonItem>

                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HAOREMWORK && formikProps.errors.HAOREMWORK}
                      </IonLabel>

                      <IonItem>
                        <IonLabel class="ion-text-nowrap" >Are you related to anyone who currently works for the City of Orem? </IonLabel>
                        <div className="select">
                          <select id="HARELATED"
                            onChange={(e) => {
                              formikProps.values.HARELATED = e.target.value;
                              if (e.target.value === "Y") {
                                setShowRelated(true);
                              }
                              else {
                                setShowRelated(false);
                              }
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HARELATED}  >
                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                          <span className="focus"></span>
                        </div>

                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HARELATED && formikProps.errors.HARELATED}
                      </IonLabel>

                      {showRelated &&
                        <div style={{ marginLeft:'50px' }}>
                        <IonItem>
                          <IonLabel position="floating" >*Name of Relative</IonLabel>
                          <IonInput
                            type="text"
                            name="HARELNAME"
                            onIonChange={formikProps.handleChange}
                            min="1"
                            max="50"
                            placeholder=""
                            autocomplete="on"
                            value={formikProps.values.HARELNAME}
                            required={true}
                          />
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" >*Relationship</IonLabel>
                          <IonInput
                            type="text"
                            name="HARELREL"
                            onIonChange={formikProps.handleChange}
                            min="1"
                            max="50"
                            placeholder=""
                            autocomplete="on"
                            value={formikProps.values.HARELREL}
                            required={true}
                          />
                        </IonItem>
                        </div>
                      }


                      <IonItem>
                        <IonLabel class="ion-text-nowrap" > Do you have a valid driver license? </IonLabel>
                        <div className="select">
                          <select id="HAVALIDDL"
                            onChange={(e) => {
                              formikProps.values.HAVALIDDL = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HAVALIDDL}  >
                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                          <span className="focus"></span>
                        </div>

                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HAVALIDDL && formikProps.errors.HAVALIDDL}
                      </IonLabel>

                      <IonItem>
                        <IonLabel class="ion-text-nowrap" >Do you have a commerical driver license?</IonLabel>
                        <div className="select">
                          <select id="HACDL"
                            onChange={async (e) => {
                              if (e.target.value === "Y") {
                                const confirmed = await showModalAndWait();
                              }
                              else {
                                formikProps.values.HACDL = e.target.value;
                                formikProps.handleChange(e);
                              }

                            }
                            }
                            value={formikProps.values.HACDL}  >
                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                          <span className="focus"></span>
                        </div>
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HACDL && formikProps.errors.HACDL}
                      </IonLabel>

                   

                      <IonItem>
                        <IonLabel class="ion-text-nowrap" >Are you claiming Veteran's Preference? </IonLabel>
                        <div className="select">
                          <select id="HAMILITARY"
                            onChange={(e) => {
                              formikProps.values.HAMILITARY = e.target.value;
                              if (e.target.value === "Y") {
                                setShowVeteran(true);
                              }
                              else {
                                setShowVeteran(false);
                              }

                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HAMILITARY}  >
                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                          <span className="focus"></span>
                        </div>
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HAMILITARY && formikProps.errors.HAMILITARY}
                      </IonLabel>

                      {showVeteran && (
                        <IonItem>
                          <IonIcon icon={alertCircleOutline} size="large" color="danger" slot="start" />
                          <IonLabel class="ion-text-nowrap" color="danger">Do not forget to upload your DD-214 form in next step</IonLabel>
                        </IonItem>
                        )}

                      <IonItem>
                        <IonLabel class="ion-text-nowrap" >What is the highest level of education you have completed? </IonLabel>
                        <div className="select">
                          <select id="HAHDEGREE"
                            onChange={(e) => {
                              formikProps.values.HAHDEGREE = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HAHDEGREE}  >
                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Not yet graduated high school">Not yet graduated high school</option>
                            <option value="High school diploma or equivalent">High school diploma or equivalent</option>
                            <option value="Vocational training and/or certification">Vocational training and/or certification</option>
                            <option value="Some college">Some college</option>
                            <option value="Associate's degree">Associate's degree</option>
                            <option value="Bachelor's degree">Bachelor's degree</option>
                            <option value="Master's degree">Master's degree</option>
                            <option value="PhD degree">PhD degree</option>
                          </select>
                          <span className="focus"></span>
                        </div>
                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HAHDEGREE && formikProps.errors.HAHDEGREE}
                      </IonLabel>               

                    </IonCardContent>
                  </div>
                </IonCard>              

                <IonCard>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                    <IonCardHeader>
                      <IonCardTitle>How would you like to enter education and employment history?</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem>
                        <IonLabel >Your preference?</IonLabel>
                        <div className="select">
                          <select id="HARESUME"
                            onChange={(e) => {
                              formikProps.values.HARESUME = e.target.value;
                              formikProps.handleChange(e);
                            }
                            }
                            value={formikProps.values.HARESUME}  >
                            <option value="null" style={{ visibility: 'hidden' }} >Select One</option>
                            <option value="Y" >Upload Resume/CV</option>
                            <option value="N">Enter Manually</option>
                          </select>
                          <span className="focus"></span>
                        </div>

                      </IonItem>
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.HARESUME && formikProps.errors.HARESUME}
                      </IonLabel>
                    </IonCardContent>
                  </div>
                </IonCard>

                <IonCard>
                  <div style={{ paddingLeft: '10px', paddingRight: '10px', textAlign: 'left' }}>
                    <IonCardHeader>
                      <IonCardTitle>Set Your Password</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                
                              <IonCol size="1">
                              </IonCol>
                              <IonCol size="10">
                                <IonItem color="transparent">
                                  <IonInput
                                    type="password"
                                    name="PASSWORD"
                                    placeholder="Enter your password"
                                    onIonChange={formikProps.handleChange}
                                value={formikProps.values.PASSWORD}
                                    required={true}
                                  />
                            </IonItem>
                            <div>
                            <IonLabel color="danger" className="ion-padding">
                              {formikProps.touched.PASSWORD && formikProps.errors.PASSWORD}
                            </IonLabel>
                            </div>
                              </IonCol>
                              <IonCol size="1">

                              </IonCol>
                             
                              <IonCol size="1">

                              </IonCol>
                          <IonCol size="10">
                            <IonItem color="transparent" >
                              <IonInput
                                type="password"
                                name="CONFIRMPASSWORD"
                                placeholder="Confirm your password by entering it again"
                                onIonChange={formikProps.handleChange}
                                value={formikProps.values.CONFIRMPASSWORD}
                                required={true}
                              />
                            </IonItem>

                              <IonLabel color="danger" className="ion-padding">
                                {formikProps.touched.CONFIRMPASSWORD && formikProps.errors.CONFIRMPASSWORD}
                              </IonLabel>

                          </IonCol>
                          <IonCol size="1">
                          </IonCol>
                         
                            

                        </IonRow>
                      </IonGrid>

                     
                      <IonLabel color="danger" className="ion-padding">
                        {formikProps.touched.CONFIRMPASSWORD && formikProps.errors.CONFIRMPASSWORD}
                      </IonLabel>

                    </IonCardContent>
                  </div>
                </IonCard>
                

                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  <IonButton
                    color="primary"
                    expand="block"
                    type="submit"
                    size="large"
                  >Proceed</IonButton>

                </div>


                <IonModal isOpen={showModalCDMConfirm} backdropDismiss={false} onDidDismiss={() => {
                  setShowModalCDMConfirm(false);
                }}>
                  <ModalCDLConfirm
                    setShowModalCDMConfirm={setShowModalCDMConfirm}
                    handleCDLChange={(value) => handleCDLChange(formikProps.setFieldValue, value)}
                  ></ModalCDLConfirm>
                </IonModal>

                    
                 </form>

            )}


            </Formik>  

          <IonModal isOpen={showModalApply} backdropDismiss={false} onDidDismiss={() => {
            if (email === undefined) {
              setShowModalApply(true);
            }
            else {
              setShowModalApply(false);
            }
             }}>
            <ModalApply jobid={jobindex} setShowModalApply={setShowModalApply} ></ModalApply>
          </IonModal>


        


          
       </div>

      </IonContent>
    </IonPage>
  )
}



export default Application;
