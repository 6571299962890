import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader,  IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonModal, IonPage, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import React, { useEffect,useState } from 'react';
import { useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';
import { GetAppUserStatus, GetAppFileList, FinishAppUpload, GetAppEdList, GetAppWkList } from '../data/api';
import Camera from '../components/Camera';
import ModalEdAdd from '../components/ModalEdAdd';
import ModalWkAdd from '../components/ModalWkAdd';
import { GetDailyCode } from '../data/utilities';


interface StateProps {
  title: string
}

interface RouteProps extends RouteComponentProps<{ email: any, jobindex: any }> { }
interface GenericProps extends RouteProps, StateProps { }

const Manual: React.FC<GenericProps> = ({ match, title }) => {


  const [present] = useIonAlert();
  const [showModalEd, setShowModalEd] = useState(false);
  const [showModalWk, setShowModalWk] = useState(false);
  const [showRefresh, setRefresh] = useState(false);

  let email = match.params.email;
  let code = GetDailyCode(email);

  let jobindex = 0;
  if (match.params.jobindex != undefined) {
    jobindex = match.params.jobindex
  }

  const queryUser = useQuery("queryStatus", () => GetAppUserStatus(email));
  const queryEd = useQuery("queryEdList", () => GetAppEdList(code, email));
  const queryWk = useQuery("queryWkList", () => GetAppWkList(code, email));
  const queryFiles = useQuery("queryFileList", () => GetAppFileList(code, email));


  useEffect(() => {
    queryEd.refetch();
    queryWk.refetch();
    queryFiles.refetch();
  }, [showModalEd, showModalWk, showRefresh]);



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="light">Manually Enter Education/Employment Data</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">



        {queryUser.isSuccess && queryUser.data == "X" && (
          
          <>
            {queryEd.isSuccess && (
              <>
               
                <IonCard>
                  <IonCardHeader color="medium">
                    <IonItem color="transparent" lines="none">
                      <IonCardTitle>Educational Information</IonCardTitle>
                      <IonButton slot="end" color="success" style={{ height: '40px' }} onClick={() => setShowModalEd(true)} >
                        <IonIcon icon={addOutline} size="large" class="ion-text-end" />
                      </IonButton>
                    </IonItem>
                  </IonCardHeader>
                  <IonCardContent >
                    {queryEd.isSuccess && queryEd.data != "" && queryEd.data.map((dataString: any) => (
                      <>
                        <IonItem key={dataString.heschool}>
                          <IonLabel className="ion-text-wrap">
                            <strong className="ion-text-uppercase">{dataString.heschool}</strong><p></p>
                            <div style={{ paddingLeft: '20px' }}>
                              Subject: {dataString.hesubject}<p></p>
                              Degree: {dataString.hedegree}<p></p>
                              Completion: {dataString.hecomplete === "Y" ? (<>Yes</>) : (<>No</>)}
                            </div>
                          </IonLabel>
                        </IonItem>
                      </>
                    ))}
                  </IonCardContent>
                </IonCard>
                     
                <IonCard>
                      <IonCardHeader color="medium">
                        <IonItem color="transparent" lines="none">
                          <IonCardTitle>Work Experience Information</IonCardTitle>
                          <IonButton slot="end" color="success" style={{ height: '40px' }} onClick={() => setShowModalWk(true)} >
                            <IonIcon icon={addOutline} size="large" class="ion-text-end" />
                          </IonButton>
                        </IonItem>
                      </IonCardHeader>
                      <IonCardContent >
                        {queryWk.isSuccess && queryWk.data !="" && queryWk.data.map((dataString: any) => (
                          <>
                            <IonItem key={dataString.hwecompany}>
                              <IonLabel className="ion-text-wrap">
                                <strong className="ion-text-uppercase">{dataString.hwecompany}</strong><p></p>
                                <div style={{ paddingLeft: '20px' }}>
                                  Title: {dataString.hwejobtitl}<p></p>
                                  Duration: {dataString.hwefrom} - {dataString.hweto}<p></p>
                                  Job Description: {dataString.hweduties}
                                </div>
                              </IonLabel>
                            </IonItem>
                          </>
                        ))}
                      </IonCardContent>
                    </IonCard>
                  
                <IonCard>
                  <IonCardContent>
                    <Camera useremail={email} setRefresh={setRefresh} />
                  </IonCardContent>
                </IonCard>
                <IonCard>
                  <IonCardContent>
                    {queryFiles.isSuccess && queryFiles.data.length > 0 && queryFiles.data.map((dataString: any) =>
                    (
                      <>
                        <IonItem>
                          <IonLabel>{dataString.filename}</IonLabel>
                          <IonLabel className="ion-text-end" slot="end">{dataString.timestamp}</IonLabel>
                        </IonItem>
                      </>
                    ))
                    }
                  </IonCardContent>
                </IonCard>
            

              </>
            )}
          </>
         )
        }

        {queryFiles.isSuccess && queryEd.data!="" && jobindex != 0 && queryUser.data == "X" &&
          (
            <>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>

                <IonButton
                  color="primary"
                  expand="block"
                  onClick={async () => {
                    const response = {
                      dailycode: GetDailyCode(email),
                      email: email
                    };

                    const returnValue = await FinishAppUpload(response);
                    if (returnValue === "success") {
                      window.location.href = "/tabs/EEQuestionnaire/" + email + "/" + jobindex.toString();
                    }
                    else {
                      present("Sorry that we are unable to process your request due to the following error:" + returnValue);
                    }
                  }
                  }
                  size="large"
                >Proceed</IonButton>
              </div>
            </>
          )
        }

        {queryFiles.isSuccess && queryEd.data !="" && jobindex == 0 && queryUser.data == "X" &&
          (
            <>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <IonButton
                  color="primary"
                  expand="block"
                  size="large"
                  onClick={async () => {
                    const response = {
                      dailycode: GetDailyCode(email),
                      email: email
                    };

                    const returnValue = await FinishAppUpload(response);
                    if (returnValue === "success") {
                      present("You have successfully created your profile with us.  Now you can click<a href='/tabs/joblist'> here</a> to view the job list and apply for the jobs which you are interested in. ");
                    }
                    else {
                      present("Sorry that we are unable to process your request due to the following error:" + returnValue);
                    }
                  }
                  }
                >Final Submit</IonButton>
              </div>
            </>
          )
        }


        <IonModal isOpen={showModalEd} backdropDismiss={false} onDidDismiss={() => { setShowModalEd(false); }}>
          <ModalEdAdd useremail={email} setShowModalEd={setShowModalEd} ></ModalEdAdd>
        </IonModal>

        <IonModal isOpen={showModalWk} backdropDismiss={false} onDidDismiss={() => { setShowModalWk(false); }}>
          <ModalWkAdd useremail={email} setShowModalWk={setShowModalWk} ></ModalWkAdd>
        </IonModal>
      
      </IonContent>
    </IonPage>
  )
}



export default Manual;
