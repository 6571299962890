import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { IonAvatar, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonText, IonToggle } from '@ionic/react';
import { moonOutline, homeOutline,   personOutline, searchOutline, starOutline, notificationsOutline, locateOutline, locationOutline, timeOutline, cellularOutline, callOutline, videocamOutline } from 'ionicons/icons';
import './Menu.css'
import useAuth from '../data/useAuth';
import { getSessionStorageOrDefault } from '../data/utilities';
import { useGlobalContext } from '../components/MyGlobalContext';
import { GetAppOperationHours, LogoutApp } from '../data/api';
import './Generic.css'
import { useQuery } from 'react-query';



const routes = {
  appPages: [
   /* { title: 'Home', path: '/tabs/joblist', icon: homeOutline },*/
  ]
};

interface Pages {
  title: string,
  path: string,
  icon: string,
  routerDirection?: string
}

interface StateProps {
  darkMode: boolean;
}

interface DispatchProps {
  setDarkMode: any
}

interface MenuProps extends StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, setDarkMode }) => {
  const location = useLocation();
  const { user, setUser } = useAuth();
  const loggedIn: boolean = user ? true : false;

  const {  setShowSearchText } = useGlobalContext();
  const {  showDept, setShowDept } = useGlobalContext();
  const {  setShowType } = useGlobalContext();
  const {  setShowSort } = useGlobalContext();

  const hoursQuery = useQuery("Hours", () => GetAppOperationHours());


  useEffect(() => {
    setShowSearchText(getSessionStorageOrDefault("showSearchText", ""));
    setShowDept(getSessionStorageOrDefault("showDept", []));
    setShowType(getSessionStorageOrDefault("showType", []));
    setShowSort(getSessionStorageOrDefault("showSort", ""));

    

  }, []);



  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => (
        <IonMenuToggle key={p.title} auto-hide="false">
          { p.title.indexOf("Reservation")>0 ?
            (
              <IonItem  href={p.path}  className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
              <IonIcon slot="start" icon={p.icon} />
              <IonLabel>{p.title}</IonLabel>
            </IonItem>
            ) :
            (
              <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined} >
                <IonIcon slot="start" icon={p.icon} />
                <IonLabel>{p.title}</IonLabel>
              </IonItem>
            )
          }
        </IonMenuToggle>
      ));
  }

  function ExistApp() {
    setUser(false);
    LogoutApp();
  }

  return (

    <IonMenu  type="overlay" contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonItem>
            <div style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
              <IonAvatar style={{ width: '200px', height: '200px' }} onClick={() => window.location.href = "/tabs/joblist/"}>
                <img src="/assets/icon/joblogo.png" />
              </IonAvatar>
            </div>
          </IonItem>
         
        </IonList>
        <IonList lines="none">
          {renderlistItems(routes.appPages)}

          {!loggedIn && (
            <>
            <IonItem href="/tabs/joblist">
                <IonIcon slot="start" icon={homeOutline}></IonIcon>
              <IonLabel>Current Openings</IonLabel>
            </IonItem>

            <IonItem href="/tabs/login">
              <IonIcon slot="start" icon={personOutline}></IonIcon>
              <IonLabel>Login</IonLabel>
              </IonItem>

              <IonItem href="/tabs/login">
                <IonIcon slot="start" icon={notificationsOutline}></IonIcon>
                <IonLabel>Request Notification </IonLabel>
              </IonItem>


            </>
            )
          }

          {loggedIn && (
            <>
              <IonItem href="/tabs/usermenu">
                <IonIcon slot="start" icon={homeOutline}></IonIcon>
                <IonLabel>Home</IonLabel>
              </IonItem>

              <IonItem href="/tabs/joblist">
                <IonIcon slot="start" icon={searchOutline}></IonIcon>
                <IonLabel>Search Jobs</IonLabel>
              </IonItem>

              <IonItem href="/tabs/userNotification">
                <IonIcon slot="start" icon={notificationsOutline}></IonIcon>
                <IonLabel>Request Notification </IonLabel>
              </IonItem>

            <IonItem href="/tabs/logout">
              <IonIcon slot="start" icon={personOutline}></IonIcon>
              <IonLabel>Logout</IonLabel>
            </IonItem>
            </>
            )
          }

        

         
          
          <IonItem href="javascript:void(0);"
            onClick={() => window.open('https://drive.google.com/file/d/1QIlD8AFfArNrP62LbabSfoX3HAzkHd-o/view?usp=sharing', '_blank')}>
            <IonIcon slot="start" src='../assets/icon/package.svg'></IonIcon>
            <IonLabel>City Benefits Summary</IonLabel>
          </IonItem>
          <IonItem href="javascript:void(0);"
            onClick={() => window.open('https://docs.google.com/spreadsheets/d/1KdZ7YF0MBJkEOChgmWFQ-kcVkafRF3ZRCyJAvuN7HZA/edit#gid=1863688675', '_blank')}>
            <IonIcon slot="start" src='../assets/icon/description.svg'></IonIcon>
            <IonLabel>Job Desc./Pay Ranges</IonLabel>
          </IonItem>
          
          {/*<IonItem href="https://orem.org/help/">*/}
          {/*  <IonIcon slot="start" icon={helpCircleOutline}></IonIcon>*/}
          {/*  <IonLabel>Need Help?</IonLabel>*/}
          {/*</IonItem>*/}

          {/*<IonItem href="/tabs/video">*/}
          {/*  <IonIcon slot="start" icon={videocamOutline}></IonIcon>*/}
          {/*  <IonLabel>Orem Jobs Preview</IonLabel>*/}
          {/*</IonItem>*/}

        </IonList>
        <IonList lines="none">
          <IonListHeader>Settings</IonListHeader>
          <IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
          </IonItem>
        </IonList>



        {hoursQuery.isSuccess && hoursQuery.data != null && (
          <>
            <IonList>
              <IonListHeader>Contact Info</IonListHeader>
              <div className="ion-text-wrap, ion-text-center">
                <IonLabel style={{ fontSize: '12px' }}>56 Center Street, Orem, UT 84057</IonLabel>
                <br></br>
                <IonLabel style={{ fontSize: '12px' }}>(801) 229-7001</IonLabel>
                <br></br>

                <IonLabel style={{ fontSize: '12px' }}><strong>Hours</strong>:&nbsp;
                  {hoursQuery.data.status1 === "Open" &&
                    (
                    <>
                      <strong style={{ color: 'green' }}>{hoursQuery.data.status1} </strong>
                    </>
                    )
                  }
                  {hoursQuery.data.status1 === "Closed" &&
                    (
                      <>
                        <strong style={{ color: 'red' }}>{hoursQuery.data.status1} </strong>
                      </>
                    )
                  }
                  - {hoursQuery.data.status2} {hoursQuery.data.hours}</IonLabel>
              </div>
            </IonList>
          </>
        )}

       

      </IonContent>
    </IonMenu>

  );
};

export default Menu;
