import { IonButton, IonButtons, IonContent, IonHeader, IonIcon,  IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { returnDownBackOutline } from 'ionicons/icons';
import React from 'react';
import './Generic.css';
import ReactPlayer from "react-player"



const Video: React.FC = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Orem Jobs Preview</IonTitle>
          <IonButtons slot="end" onClick={() => { window.location.href = 'tabs/joblist' }} color="transparent">
            <IonButton>
              <IonIcon icon={returnDownBackOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {/*<div style={{ height: '100px' }} />*/}
      

        <div>
          <ReactPlayer
            
            
            width="100%"
            url="https://youtu.be/YV3rMguC8hQ"
          />
        </div>



      </IonContent>
    </IonPage>
  )
}



export default Video;
